@import '../../variables.css';

.impressumContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: var(--spacing-xl);
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: var(--font-family);
    text-align: left;
}

.impressumContainer h1,
.impressumContainer h2,
.impressumContainer h3,
.impressumContainer p {
    display: block;
    width: 100%;
    margin-bottom: var(--spacing-m);
}

.impressumSection {
    border-bottom: 1px solid var(--hover-primary-color);
    padding-bottom: var(--spacing-m);
    margin-bottom: var(--spacing-m);
}

.impressumSection h2 {
    font-size: var(--size-h5);
    font-weight: bold;
    color: var(--text-color);
}

.impressumSection h3 {
    font-size: var(--size-h6);
    font-weight: bold;
    color: var(--text-color);
}

.impressumSection p {
    font-size: var(--font-size-base);
    color: var(--text-color);
}

.impressumSection a {
    color: var(--hover-primary-color);
    text-decoration: none;
    font-weight: bold;
}

.impressumSection a:hover {
    text-decoration: underline;
}
