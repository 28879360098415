@import '../../variables.css';

/* Gesamt-Layout */
.profile-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  padding: 40px;
  flex-wrap: wrap;
}

/* Profil- und Buchungscontainer */
.profile-container, .bookings-container {
  flex: 1;
  padding: 30px;
  background: var(--background-color);
  box-shadow: var(--box-shadow);
  border-radius: 12px;
  min-width: 300px;
}

/* Überschriften Styling */
.profile-container h2, .bookings-container h2 {
  color: var(--accent-color);
  margin-bottom: 20px;
  text-align: center;
}

/* Profil-Anzeige */
.profile-details p {
  font-size: 16px;
  margin: 8px 0;
  color: var(--text-color);
}

/* Profilformular als Grid */
.profile-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

/* Eingabefelder Styling */
.profile-form input[type="text"],
.profile-form input[type="number"],
.profile-form input[type="email"] {
  padding: 12px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 16px;
  border: 1px solid var(--border-color);
  width: 100%;
}

/* Fokus für Inputs */
.profile-form input:focus {
  outline: none;
  border-color: var(--highlight-color);
  box-shadow: 0 0 8px var(--highlight-color);
}

/* Label Styling */
.profile-form label {
  font-size: 14px;
  color: var(--text-color);
  margin-bottom: 5px;
  display: block;
}

/* Inputs, die die volle Breite benötigen */
.profile-form .full-width {
  grid-column: span 2;
}

/* Speichern-Button */
.profile-form .save-button {
  grid-column: span 2;
  margin-top: 10px;
}

/* Buchungen Styling */
.bookings-container ul {
  list-style: none;
  padding: 0;
}

.bookings-container li {
  padding: 15px;
  margin-bottom: 20px;
  background: var(--background-color);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  position: relative;
  transition: transform 0.2s ease;
}

/* Hover Effekt für Buchungen */
.bookings-container li:hover {
  transform: translateY(-5px);
}

/* Trenner zwischen Buchungen */
.bookings-container li:not(:last-child)::after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 10%;
  width: 80%;
  height: 2px;
  background: var(--border-color);
  border-radius: 2px;
}

/* Buchungsinformationen */
.bookings-container li h3 {
  color: var(--accent-color);
  margin-bottom: 10px;
}

.bookings-container li p {
  margin: 4px 0;
  color: var(--text-color);
}
