@import '../../variables.css';

.main {

    display: grid;
    gap: 70px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
    justify-content: center;
    align-items: center;
    padding-top: 100px;

}
