@import '../../variables.css';

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--nav-150-hight);
    width: 100%;
    box-shadow: var(--box-shadow);
    position: sticky;
    top: 0;
    background-color: var(--nav-background-color);
    z-index: 1000;
    padding: 0 var(--spacing-m);
}

/* Logo */
.logo-container img {
    width: 150px;
    padding-top: var(--spacing-small);
}

/* Navigation Links */
.nav-links {
    display: flex;
    gap: var(--spacing-xl);
    list-style: none;
    transition: all 0.3s ease-in-out;
}

.nav-link {
    text-decoration: none;
    color: var(--text-color);
    font-size: var(--font-size-20);
    font-weight: bold;
    padding-bottom: var(--spacing-xs);
    transition: color 0.3s ease-in-out;
    cursor: pointer;
}

.nav-link:hover {
    color: var(--hover-primary-color);
}

/* 🎨 Burger Menü Button */
.menu-toggle {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 40px;
    height: 30px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1500;
}

.menu-toggle div {
    width: 100%;
    height: 4px;
    background-color: var(--primary-color);  /* Hier wird die Primärfarbe genutzt */
    border-radius: 2px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* Wenn das Menü geöffnet ist */
.menu-toggle.open div:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
    background-color: var(--secondary-color); /* Farbwechsel zum Secondary-Color */
}

.menu-toggle.open div:nth-child(2) {
    opacity: 0;
}

.menu-toggle.open div:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
    background-color: var(--secondary-color); /* Farbwechsel zum Secondary-Color */
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .menu-toggle {
        display: flex;
    }

    .nav-links {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: var(--nav-150-hight);
        left: 0;
        width: 100%;
        background-color: var(--nav-background-color);
        box-shadow: var(--box-shadow);
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        padding: var(--spacing-m);
    }

    .nav-links.active {
        transform: translateX(0);
    }

    .nav-link {
        font-size: var(--size-h4);
        text-align: center;
        padding: var(--spacing-m) 0;
    }
}
