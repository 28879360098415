.courseContainer {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }

  .coursesh1 {
    text-align: center;
    font-size: 24px;
    color: var(--text-color);
    margin-bottom: 20px;
  }

  /* Karten-Styling */
  .courseCard {
    background: var(--background-color);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
    overflow: hidden;
    cursor: pointer;
  }

  /* Header mit Titel & Button */
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
  }

  .courseTitle {
    font-size: 20px;
    color: var(--text-color);
  }

  .toggleButton {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: var(--primary-color);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  /* Beschreibung - Höhe wird dynamisch angepasst */
  .courseDescription {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    padding: 0 10px;
    font-size: 16px;
    color: var(--text-color);
  }

  /* Automatische Höhe für geöffnete Beschreibung */
  .courseCard.expanded .courseDescription {
    max-height: 1000px; /* Automatisch erweiterbar */
    opacity: 1;
    padding-top: 10px;
  }

  /* Button Container */
  .buttonContainer {
    text-align: right;
    margin-top: 10px;
  }

  .courseButton {
    width: 100%;
    padding: 10px;
    background: linear-gradient(90deg,var(--secondary-color), var(--primary-color));
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }

  .courseButton:hover {
    background: linear-gradient(90deg, var(--hover-secondary-color), var(--hover-primary-color));
  }

  /* Mobile Anpassungen */
  @media (max-width: 768px) {
    .courseCard {
      padding: 10px;
    }

    .courseTitle {
      font-size: 18px;
    }

    .courseButton {
      font-size: 14px;
    }

    .toggleButton {
      font-size: 16px;
    }
  }
