@import '../../variables.css';

header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    Margin-top: 100px;
}


.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
}


.logoContainer img {
    width: 1px;
    transition: transform 2s ease, width 2s ease;
}

.logoContainer.loaded img {
    width: 700px;
    transition: transform 2s ease, width 2s ease;
}



.contentContainer p{
    color: white;
    text-align: center;
    font-size: 1.5em;
    opacity: 0;

}

.contentContainer.loaded p{
    opacity: 1;
}

.btn {
    margin-top: var(--spacing-xxl);
    padding: 10px 20px;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: white; /* Textfarbe */
    border: none;
    border-radius: 10px; /* Runde Ecken */
    background: linear-gradient(90deg,var(--secondary-color), var(--primary-color) ); /* Farbverlauf von Lila zu Blau */
    cursor: pointer;
    box-shadow: var(--box-shadow); /* Leichter Schatten */
    opacity: 0; /* Button ist am Anfang unsichtbar */
}

.btn.loaded {
    opacity: 1;
    transition: opacity 2s ease;
}

/* Hover-Effekt */
.btn:hover {
    background: linear-gradient(90deg, var(--hover-secondary-color), var(--hover-primary-color)); /* Dunklere Farbtöne beim Hover */
}

button.pulsing { /* MARKIERT */
    animation: pulse 1.5s infinite ease-in-out; /* Startet das Pulsieren */ /* Button wird sichtbar */
}

/* Pulsieren des Buttons */
@keyframes pulse {
    0% {
        transform: scale(0.95);
    }
    50% {
        transform: scale(1); /* Leichtes Pulsieren */
    }
    100% {
        transform: scale(0.95);
    }
}
