/* Allgemeines Styling */
.homeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
}

/* Hero Section */
.sectionHero {
    position: relative;
    width: 100%;
    height: 60vh;
    background: url('/public/assets/images/hero-bg.jpg') center/cover no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.contentBox {
    position: relative;
    z-index: 2;
    padding: 20px;
}

.homeH1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.homeH3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.ctaButton {
    padding: 10px 20px;
    font-size: 1rem;
    background: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

/* Info-Abschnitt */
.sectionInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 40px 20px;
    text-align: center;
}

.card {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 300px;
}

.infoButton {
    margin-top: 10px;
    padding: 8px 15px;
    font-size: 0.9rem;
    background: #28A745;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

/* Responsive Design */
@media (min-width: 768px) {
    .sectionInfo {
        flex-direction: row;
        justify-content: center;
    }
}
