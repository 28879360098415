@import '../../variables.css';

.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--background-color);
}

.auth-box {
    background: var(--background-color);
    padding: var(--spacing-l);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    width: 90%;
    height: 90%;
    max-width: 70%;
    max-height: 70%;
    text-align: center;
}

h2 {
    margin-bottom: 20px;
    font-size: var(--size-h1);
    color: var(--text-color);
}

.input-group {
    margin-bottom: var(--spacing-m);
}

.login-input {
    width: 75%;
    height: 100%;
    padding: 12px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    visibility: visible;
}

.login-input:focus {
    border-color: black;
}

.error-message {
    color: red;
    font-size: 0.9rem;
}

.toggle-auth {
    margin-top: 10px;
    cursor: pointer;
    color: var(--primary-color);
}

.toggle-auth:hover {
    text-decoration: underline;
}


.forgot-password-link {
    margin-top: var(--spacing-xl);
    text-decoration: none;
    cursor: pointer;
    color: var(--primary-color);
}

.forgot-password-link:hover {
    text-decoration: underline;
}
