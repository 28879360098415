.default {
    padding: 10px 20px;
    margin-right: 1rem;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: white; /* Textfarbe */
    border: none;
    border-radius: 10px; /* Runde Ecken */
    background: linear-gradient(90deg,var(--secondary-color), var(--primary-color) ); /* Farbverlauf von Lila zu Blau */
    cursor: pointer;
    transition: background 0.3s ease;
    box-shadow: var(--box-shadow); /* Leichter Schatten */
    opacity: 1; /* Button ist am Anfang unsichtbar */
}

/* Hover-Effekt */
.default:hover {
    background: linear-gradient(90deg, var(--hover-secondary-color), var(--hover-primary-color)); /* Dunklere Farbtöne beim Hover */
}
