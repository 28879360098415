@import '../../../variables.css';

.switch-label {
    width: 70px; /* Feinanpassung der Breite */
    height: 38px; /* Feinanpassung der Höhe */
    position: relative;
    display: inline-block;
    background-color: var(--background-color);
    border-radius: 45px;
    box-shadow: inset 0 3px 8px rgba(0, 0, 0, 0.4), inset 0 -3px 8px rgba(255, 255, 255, 0.4);
    cursor: pointer;
    transition: 0.3s;
}

.switch-label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 4px;
    width: 32px; /* Kleinere Kugel für bessere Passform */
    height: 32px;
    background: linear-gradient(180deg, var(--primary-color), var(--secondary-color));
    border-radius: 50%;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

.switch-input {
    width: 0;
    height: 0;
    visibility: hidden;
    outline: none;
}

.switch-input:checked + label {
    background-color: var(--secondary-color);
}

.switch-input:checked + label:after {
    transform: translateX(-100%);
    left: 65px; /* Feineinstellung der Position für Dark Mode */
    background: linear-gradient(180deg, var(--secondary-color), var(--primary-color));
}

.switch-label:active:after{
    width: 40px; /* Sanfter Übergang bei Klick */
}
