@import '../../variables.css';

.datenschutz-container {
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--background-color);
  padding: var(--spacing-m);
  max-width: 80%;
  margin: 0 auto;
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow);
}

.datenschutz-container h1,
.datenschutz-container h2,
.datenschutz-container h3 {
  color: var(--text-color);
  margin-bottom: var(--spacing-m);
}

.datenschutz-container p {
  margin-bottom: var(--spacing-m);
}

.datenschutz-container a {
  color: var(--hover-primary-color);
  text-decoration: none;
}

.datenschutz-container a:hover {
  text-decoration: underline;
}

.datenschutz-container ul {
  margin-bottom: var(--spacing-m);
  padding-left: var(--spacing-m);
}

.datenschutz-container li {
  margin-bottom: var(--list-item-spacing);
}
