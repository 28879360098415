:root {
    /* Colors */
    --primary-color: #b06ab3;
    --secondary-color: #4568dc;
    --hover-primary-color: #9f36a2;
    --hover-secondary-color: #849df2;
    --text-color: aliceblue;
    --dark-bg-text-color: aliceblue;
    --hover-color: linear-gradient(90deg,var(--secondary-color), var(--primary-color) );
    --background-color: #0b0004;

    /* Fonts and Spacing */
    --font-family: Montserrat, system-ui, san-serif;
    --font-weight: 400;
    --font-size-base: 16px;
    --font-size-20: 20px;
    --size-h1: 2rem;
    --size-h3: 1.5rem;
    --size-h4: 1.4rem;
    --size-h5: 1.3rem;
    --size-h6: 1rem;
    --spacing-xxs: .25rem;
    --spacing-xs: .5rem;
    --spacing-s: .75rem;
    --spacing-m: 1rem;
    --spacing-l: 1.25rem;
    --spacing-xl: 2rem;
    --spacing-xxl: 2.5rem;

    /* Shadows */
    --box-shadow: 0rem .25rem .6rem rgba(234, 132, 219, 0.4);
    --card-box-shadow: 0rem 0rem 2rem  rgba(234, 132, 219, 0.4);

    /* Imagesizes */
    --logo-base-width:100px;
    --logo-300-width:300px;

    /* Navigation */
    --nav-base-hight: 100px;
    --nav-150-hight: 150px;
    --nav-background-color: #08000f;

    /*footer*/

    --footer-base-height: 200px;
    --footer-background-color: #08000f;

    /* Dark/Light Toggle */
  }

  [data-theme = "light"] {
    /* Colors */
    --primary-color: #b06ab3;
    --secondary-color: #4568dc;
    --hover-primary-color: #9f36a2;
    --hover-secondary-color: #849df2;
    --text-color: black;
    --dark-bg-text-color: aliceblue;
    --hover-color: linear-gradient(90deg,var(--secondary-color), var(--primary-color) );
    --background-color: white;

    /* Fontsize and Spacingsizes */
    --font-family: "Montserrat", system-ui;
    --font-weight: 400;
    --font-size-base: 16px;
    --font-size-20: 20px;
    --spacing-xxs: .25rem;
    --spacing-xs: .5rem;
    --spacing-s: .75rem;
    --spacing-m: 1rem;
    --spacing-l: 1.25rem;
    --spacing-xl: 2rem;
    --spacing-xxl: 2.5rem;

    /* Shadows */
    --box-shadow: 0rem .25rem .6rem rgba(55, 15, 62, 0.4);

    /* Imagesizes */
    --logo-base-width:100px;
    --logo-300-width:300px;

    /* Navigation */
    --nav-base-hight: 100px;
    --nav-200-hight: 200px;
    --nav-background-color: rgb(245, 238, 244);

    /*footer*/

    --footer-background-color: rgb(245, 238, 244);
  }
