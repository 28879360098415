.contact-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.contact-container h2 {
    margin-bottom: 15px;
    font-size: 24px;
    color: #333;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-form label {
    font-size: 16px;
    color: #555;
    text-align: left;
    display: block;
    font-weight: bold;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    font-family: inherit;
}

.contact-form textarea {
    height: 120px;
    resize: none;
}

.contact-form button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.contact-form button:hover {
    background-color: #0056b3;
}

.contact-status {
    margin-top: 15px;
    font-size: 16px;
    color: #28a745;
    font-weight: bold;
}
