@import '../../variables.css';

.booking-summary-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 15px;
    border-radius: 12px;
    box-shadow: var(--card-box-shadow);
    background-color: --var(background-color);
    overflow: hidden;
  }

  .bsh1 {
    text-align: center;
    font-size: 1.8rem;
    color: var(--text-color);
    margin-bottom: 20px;
  }

  .bsh2 {
    font-size: 1.5rem;
    color: var(--text-color);
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }

  .booking-details,
  .customer-details {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--background-color);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }

  .booking-details p,
  .customer-details p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  .booking-details strong,
  .customer-details strong {
    color: var(--text-color);
  }

  .confirm-purchase {
    text-align: center;
    margin-top: 20px;
  }


  /* Mobile First: Anpassungen für größere Bildschirme */
  @media (min-width: 768px) {
    .booking-summary-container {
      display: flex;
      flex-direction: column;
    }

    .booking-details,
    .customer-details {
      flex: 1;
      margin-bottom: 30px;
    }

    .confirm-purchase button {
      font-size: 1.5rem;
    }
  }
