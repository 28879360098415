@import '../../variables.css';

.card-container {
    background-color: var(--background-color);
    overflow: hidden;
    box-shadow: var(--card-box-shadow);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    min-height: 350px; /* Einheitliche Höhe für alle Karten */
    border-radius: 10px;
    align-items: center;
}

.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;   /* Setze eine feste Breite für das Bild */
    height: 120px;  /* Setze eine feste Höhe für das Bild */
    margin: 0 auto;
    padding-top: 10px;
}

.card-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.card-body {
    padding: 15px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.card-title {
    font-size: 1.5rem;
    margin: 10px 0px;
}

.card-text {
    font-size: var(--font-size-base);
    margin-bottom: 15px;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Begrenzung auf max. 3 Zeilen */
    line-clamp: 5; /* Begrenzung auf max. 3 Zeilen */
    -webkit-box-orient: vertical;
}

.card-footer {
    margin-top: auto;
    padding-top: 10px;
}
