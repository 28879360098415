@import '../../variables.css';

.software-dev-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: var(--background-color);
}

/* Hero Section */
.hero-section {
    position: relative;
    width: 100vw;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dark-bg-text-color);
    text-align: center;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.hero-content {
    position: relative;
    z-index: 2;
    font-size: var(--size-h3);
    font-weight: bold;
    text-align: center;
}

/* Features Section */
.features-section {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    padding: var(--spacing-l);
    align-items: center;
}

.feature-card {
    background: var(--primary-color);
    color: var(--text-color);
    padding: var(--spacing-m);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    text-align: center;
    max-width: 90%;
}

.feature-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: var(--border-radius);
}

/* Mobile First - Anpassung für größere Bildschirme */
@media screen and (min-width: 768px) {
    .features-section {
        flex-direction: row;
        justify-content: center;
    }

    .feature-card {
        width: 30%;
    }
}
