@import '../../variables.css';

.dates-container {
  max-width: 100%;
  padding: var(--spacing-m);
  margin: 0 auto;
}

.course-title {
  font-size: 2rem;
  text-align: center;
  color: var(--primary-color);
  margin-bottom: var(--spacing-l);
}

.course-dates-card {
  background-color: var(background-color);
  box-shadow: var(--card-box-shadow);
  border-radius: 10px;
  padding: var(--spacing-m);
  margin-bottom: var(--spacing-m);
  transition: transform 0.2s ease-in-out;
}

.course-dates-card:hover {
  transform: translateY(-5px);
}

.date-details p {
  font-size: 1rem;
  margin: var(--spacing-s) 0;
  color: var(--text-color);
}

.date-details strong {
  color: var(--primary-color);
}

.course-dates-card .button {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: var(--spacing-m);
}

/* Mobile First: Standard für kleinere Bildschirme */

/* Ab 768px: Tablet-Ansicht */
@media (min-width: 768px) {
  .dates-container {
    max-width: 750px;
  }

  .course-title {
    font-size: 2.5rem;
  }

  .course-dates-card {
    padding: var(--spacing-l);
  }

  .date-details p {
    font-size: 1.1rem;
  }
}

/* Ab 1024px: Desktop-Ansicht */
@media (min-width: 1024px) {
  .dates-container {
    max-width: 1200px;
    padding: var(--spacing-xl);
  }

  .course-title {
    font-size: 3rem;
  }

  .course-dates-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .date-details {
    flex: 1;
  }

  .course-dates-card .button {
    width: auto;
    min-width: 150px;
  }
}
