@import '../../variables.css';

html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.mainContent {
    flex: 1 0 auto; /* Lässt den Inhalt flexibel wachsen */
    overflow: hidden; /* Optional, falls du überlaufende Inhalte verhindern willst */
}

.footerContainer {
    min-height: var(--footer-base-height);
    background-color: var(--background-color);
    color: var(--text-color);
    padding: var(--spacing-xl) 0;
    text-align: center;
    font-size: var(--font-size-base);
    border-top: 3px solid var(--hover-primary-color);
    box-shadow: var(--box-shadow);
    width: 100%;
    position: relative;
    left: 0;
    margin-top: var(--spacing-xl);
    position: relative;
}

.footerContent {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-m);
    padding: var(--spacing-l);
}

.footerContent p {
    margin: 0;
}

.footerContent nav {
    display: flex;
    gap: var(--spacing-l);
    flex-wrap: wrap;
    justify-content: center;
}

.footerContent nav a {
    color: var(--text-color);
    text-decoration: none;
    font-size: var(--font-size-base);
    font-weight: var(--font-weight);
    padding: var(--spacing-xs) var(--spacing-s);
    transition: color 0.3s ease;
}

.footerContent nav a:hover {
    color: var(--hover-primary-color);
}
