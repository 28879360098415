.thank-you-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--background-color);
  }

  .thank-you-card {
    background: var(--card-background);
    padding: 2rem;
    border-radius: 12px;
    box-shadow: var(--box-shadow);
    text-align: center;
    max-width: 500px;
  }

  h2 {
    color: var(--primary-color);
    margin-bottom: 10px;
  }

  .TYp {
    color: var(--text-color);
    font-size: 16px;
    margin: 8px 0;
  }

  button {
    margin-top: 15px;
  }
